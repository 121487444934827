<template>
  <div class="container bg-light px-5">
    <h1>Termine</h1>
    <h1>Unsere Kampagnetermine 2023 - 2024</h1>

    <div class="table-responsive">
      <table class="table">
        <tbody>
          <tr>
            <td>08.07.2023 ab 10 Uhr</td>
            <td>Sommerfest</td>
            <td>Vogelpark</td>
          </tr>
          <tr>
            <td>18.11.2023</td>
            <td>Inthronisation</td>
            <td>Wird noch bekannt gegeben</td>
          </tr>
          <tr>
            <td>09.12.2023</td>
            <td>Weihnachtsfeier</td>
            <td>Wird noch bekannt gegeben</td>
          </tr>
          <tr>
            <td>15 – 17.12.2023</td>
            <td>Weihnachtsmarkt</td>
            <td>Viernheimer Innenstadt</td>
          </tr>
          <tr>
            <td>14.01.2024</td>
            <td>Stadtempfang</td>
            <td></td>
          </tr>
          <tr>
            <td>20.01.2024</td>
            <td>Spektakel</td>
            <td>Wird noch bekannt gegeben</td>
          </tr>
          <tr>
            <td>27.01.2024</td>
            <td>Prunksitzung</td>
            <td>Bürgerhaus</td>
          </tr>
          <tr>
            <td>10.02.2024</td>
            <td>Fastnachtssamstag</td>
            <td></td>
          </tr>
          <tr>
            <td>11.02.2024</td>
            <td>Umzug Viernheim</td>
            <td></td>
          </tr>
          <tr>
            <td>13.02.2024</td>
            <td>Umzug Lorsch</td>
            <td></td>
          </tr>
          <tr>
            <td>13.02.2024</td>
            <td>Kampagnenabschluss</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { useActiveMeta, useMeta } from "vue-meta";

export default defineComponent({
  setup() {
    useMeta({
      title: "Kampagnentermine - Übersicht unserer Veranstaltungen",
      og: {
        title: "Kampagnentermine - Übersicht unserer Veranstaltungen",
      },
    });
    const metadata = useActiveMeta();
    return {
      metadata,
    };
  },
});
</script>
