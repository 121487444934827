import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_TopToolbar = _resolveComponent("TopToolbar")!
  const _component_NavigationBar = _resolveComponent("NavigationBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_SiteFooter = _resolveComponent("SiteFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_metainfo, null, {
      title: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_TopToolbar),
    _createVNode(_component_NavigationBar),
    _createVNode(_component_router_view, { class: "py-5" }),
    _createVNode(_component_SiteFooter)
  ], 64))
}