<template>
  <div class="container bg-light px-5">
    <h1>Red Maries</h1>

    <h1>Die etwas andere Garde - Red Maries</h1>

    <p>Hier kommen die Red Maries - die etwas andere Garde der Großen 3</p>

    <p>
      Was vor zwei Jahrzehnten als Verulkung der steifen Garden anderer
      Fastnachtsvereine begann, wurde erstmals auf der Prunksitzung im Jahre
      1995 ernsthafter betrieben:<br />
      Die Große 3 gründete ihre eigene Garde. Die roten Röcke gaben der
      Gruppierung ihren Namen,abgeleitet von den Funkenmariechen (in Große
      3-Kreisen: "Funky Maries"), die seit Jahren die Prinzessin zur
      Inthronisation begleiten.
    </p>

    <div class="row">
      <div class="col-12 py-5">
        <img
          alt="Logo der Red Maries"
          class="img-fluid mx-auto d-block polaroid"
          src="/images/RedMariesLogo.jpg"
        />
      </div>
    </div>

    <p>
      Der rote Rock ist noch immer das Markenzeichen der Red Maries, ergänzt
      durch die roten Gardestiefel. Die Gründerinnen unserer Garde wollten sich
      aber von einer gewöhnlichen Garde ganz bewusst abheben: Angefangen von der
      fehlenden Perücke und Hut bis hin zum Musikstil, auf den die Gardetänze
      einstudiert werden.<br />
      Von Anfang an legte das Team der Red Maries Wert auf Abwechslung im
      Programm, so gibt es in jedem Jahr einen Gardetanz zu klassischer Musik
      sowie zu einem modernen Stück, welches die Zuschauer immer besonders
      mitreißt.
    </p>

    <div class="row py-5">
      <p class="h3 pb-0 mb-0">
        Unsere Red Maries werden unterstützt von der Volksbank
        Darmstadt-Südehessen eG
      </p>
      <div class="col-12">
        <img
          alt="Logo der Volksbank Darmstadt-Südhessen eG"
          class="img-fluid mx-auto d-block polaroid"
          src="/images/logo_voba_da-sued_ein_universal_rgb.jpg"
        />
      </div>
    </div>

    <p>
      Seit vier Jahren ergänzt ein Schautanz das Programm und statt der üblichen
      Gardeschritte werden auch immerwieder Jazz-Elemente eingebaut. In der
      Anfangszeit musste die Große Drei-Garde eher heimlich auf Traineranderer
      Vereine zurückgreifen, da noch keiner der damaligen Teamer mit
      Gardeschritten oder -formationenvertraut war. Doch schon nach wenigen
      Jahren konnten aus der eigenen Mitte motivierte Tänzerinnen
      gewonnenwerden, die sich um die Choreographie der Tänze kümmerten.
    </p>

    <p>
      Die Red Maries bestehen im Durchschnitt aus zehn bis 14 Personen - wobei
      sie sehr stolz darauf sind, dass immer mindestens ein männliches "Red
      Marie" dabei ist. - Muss auch - schon allein wegen der Hebungen!!! Sieben
      Prinzessinnen waren schon bei den Red Maries aktiv - vier davon sind es
      noch heute. Manche kamen erst nach ihrer Amtszeit hinzu, doch auch aus den
      Red Maries selbst gingen seit ihrem Bestehen vier Prinzessinnen der Großen
      3 hervor.
    </p>

    <p>
      Zwei der jetzigen Red Maries sind schon von Beginn an dabei - unterbrochen
      durch Babypause oder Verpflichtungen alsaktuelle Prinzessin. Es ist nun
      mal so: Die Red Maries machen süchtig, man muss einfach immer weiter
      machen. Das zeigt schon die Altersspanne von 17-42 Jahren.
    </p>

    <p>
      Das Training, das jährlich im September beginnt und zweimal wöchentlich
      stattfindet, macht einfach Spaß. Der Jubel und der Applaus auf den
      Prunksitzungen oder anderen - auch privaten - Veranstaltungen entlohnen
      die Tänzerinnenfür alle Strapazen.
    </p>

    <p>
      Da schon seit mehreren Jahren zwei Generationen (Mutter und Tochter) auf
      der Bühne tanzen und schon immer ausreichend Nachwuchs vorhanden gewesen
      ist, entschloss man sich, eine weitere Garde zu gründen. Zum zweiten Mal
      schon sind die "Mini Maries" auf der Prunksitzung der Großen 3
      aufgetreten. Diese Kampagne hates sogar einen gemeinsamen Tanz der Red und
      Mini Maries gegeben. Man kann also sehen: Bei den Red Maries ist immerwas
      los!!
    </p>

    <p>
      Gerade deshalb sind die Red Maries gar nicht mehr wegzudenken von
      Prunksitzung, Fastnachtsumzug oder auch dem Spalier stehen bei Hochzeiten
      von Teamern, Ex-Prinzessinnen und Präsidenten der Großen 3, bei runden
      Geburtstagen oder Privatfeiern.
    </p>

    <p>
      Die Garde der Großen 3 hofft, noch lange mit ihrer etwas anderen
      Interpretation von Gardetanz begeistern zu können.Wenn Sie die Red Maries
      für ihre Veranstaltung buchen möchten, wenden Sie sich bitte an folgende
      Adresse:
    </p>

    <p>
      Gartenfachhandel Georg von Büren in Weinheim (06201-12691) oder per Mail
      an: redmaries@grossedrei.de
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useActiveMeta, useMeta } from "vue-meta";

export default defineComponent({
  setup() {
    useMeta({
      title: "Red Maries - Die etwas andere Garde der Großen 3",
      og: {
        title: "Red Maries - Die etwas andere Garde der Großen 3",
      },
    });
    const metadata = useActiveMeta();
    return {
      metadata,
    };
  },
});
</script>
