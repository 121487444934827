<template>
  <div class="container bg-light px-5">
    <h1>Aktuelles</h1>
    <h2>Termine 2023 / 2024</h2>
    <p>
      Hier geht es zu unseren
      <router-link to="/termine">
        Terminen für die Kampagne 2023 / 2024
      </router-link>
    </p>

    <h2>Wahlen 2023 / 2024</h2>
    <p>
      Am gestrigen Montag, den 03.04.2023 hat unsere erste Teamsitzung der neuen
      Kampagne stattgefunden. Wichtiger Bestandteil der Sitzung war die Wahl des
      Präsidiums für die Kampagne 2023 / 2024. Mit großer Zustimmung wurde unser
      bisheriger Präsident Tim Peterl und dessen Vize-Präsident Moritz Gerner in
      Ihrem Amt bestätigt. Als Nachrücker für David Garbe wird Carlo Brandmüller
      tatkräftig als weiterer Vize-Präsident bereitstehen.
    </p>

    <h2>Das war es!</h2>
    <p>
      Inzwischen ist unsere Kampagne leider bereits vorbei. Mit dem
      Aschermittwoch haben wir traditionell die Fastnacht wieder begraben.
    </p>
    <p>
      Wir bedanken uns bei allen Helfern, bei allen Mitgliedern, bei allen
      Gästen und ganz besonders bei unserer Prinzessin Zoe I., die uns
      wundervoll durch die gesamte Kampagne gesteuert hat.
    </p>
    <p>
      Nach der Fastnacht ist vor der Fastnacht - wir freuen uns darauf die
      nächste Kampagne mit Euch zu verbringen!
    </p>

    <h2>Kommende Termine</h2>
    <p>
      Wir freuen uns euch alle bei unserer
      <strong>Fastnachtssamstagsparty am 18.02.2023</strong> und bei der
      <strong>Straßenfastnacht am 19.02.2023</strong> in der Viernheimer
      Innenstadt begrüßen zu dürfen.
    </p>

    <h2>Zoe I. führt die Großen 3 durch die Kampagne 2020/2021</h2>
    <p>"Das Coronajahr &ndash; es ist vorbei, weiter geht die Narretei"</p>
    <p>
      Nun ist es endlich wieder soweit. Für die Narren der Großen 3 beginnt am
      heutigen 11.11.2021 die närrische Jahreszeit und die Kampagne 2021/2022.
      Nach der coronabedingten Absage der Kampagne 2020/2021 freuen sich die
      jungen Narren umso mehr, ihren Gästen echte Vernemer Fastnacht &ndash; nix
      gekaaft und nix gepacht - zu präsentieren und hoffen darauf, wieder
      gemeinsam feiern zu können.
    </p>
    <p>
      Die Narren der Großen 3 werden natürlich wieder von ihrer Prinzessin
      repräsentiert. In dieser Kampagne heißt die Lieblichkeit Zoe I., mit
      bürgerlichem Namen Zoe Noordam.
    </p>
    <p>
      <img alt="" src="/_MG_0047.jpg" class="img-fluid w-100" />
    </p>
    <p>
      Die Überraschung ist dem Präsidium, bestehend aus Präsident Tim Peterl
      sowie den Vize-Präsidenten David Garbe und Moritz Gerner, geglückt, als
      sie Zoe mit der Frage überraschten, ob sie sich vorstellen könnte, die
      Prinzessin der Großen 3 zu werden. Ihr bester Freund (und ehemaliger
      Elferrat sowie Büttenredner der Großen 3) Tarek Stucki, der gerade in
      Viernheim zu Besuch war, fragte sie, ob sie nicht am Abend etwas Zeit
      miteinander verbringen wollten. Plötzlich stand dann das Präsidium mit
      einem Blumenstrauß in der Hand vor der Tür und stellte die große Frage.
      Die Antwort ließ nicht lange auf sich warten, denn es sei ja schon der
      Traum jedes Mädchens, einmal Prinzessin zu sein.
    </p>
    <p>
      Zoe musste nun besonders lange auf ihre Inthronisation warten. Denn
      gefragt, ob sie Prinzessin der Großen 3 werden möchte, wurde sie bereits
      im Frühjahr 2020. Doch die Kampagne 2020/2021 fiel bekanntermaßen
      coronabedingt aus.
    </p>
    <p>
      Zoe ist 23 Jahre alt, hat gerade ihre Ausbildung als Kauffrau für
      Büromanagement abgeschlossen und arbeitet im Diakonissenkrankenhaus in
      Mannheim als Personalsachbearbeiterin. Vor ihrer Ausbildung war Zoe im
      Rahmen des internationalen Jugendfreiwilligendienstes ein Jahr bei der
      Deutschen Seemannsmission in Antwerpen tätig. In ihrer Freizeit engagiert
      sie sich in der KjG St. Michael und ist dort seit vielen Jahren als
      Gruppenstundenleiterin und Zeltleiterin im jährlichen Zeltlager aktiv.
      Auch als Pfarrleitung war sie bereits gewählt und ist aktuell als
      Jugendvertreterin im Pfarrgemeinderat aktiv.
    </p>
    <p>
      Ihren Weg zu den Großen 3 fand sie auch über die KjG, für die sie 2015 den
      Kinderfasching vorbereitete und seitdem alle Veranstaltungen der Großen 3
      besuchte. In den Kampagnen 2018/2019 und 2019/2020 war sie außerdem als
      Elferrat aktiv.
    </p>
    <p>
      In der Vorbereitung der Kampagne galt es, die Form des Kampagnenordens
      herauszusuchen, diesen mitzugestalten, Kleider zu kaufen (Zoe ist mit vier
      Kleidern in den Farben blau, rot, grau und schwarz gut auf eine aktive
      Kampagne vorbereitet) und natürlich das obligatorische Fotoshooting zu
      machen. Im Staatspark Fürstenlager in Bensheim sind tolle Fotos
      entstanden.
    </p>
    <p>
      Natürlich wird es auch wieder einen Kampagnensekt (Zoes Wuselfusel) und
      einen Kampagnensecco (Zoes Fizzy Bubbele) mit eigenem Etikett geben.
    </p>
    <p>
      Mit Zoe freuen sich auch ihre Mutter Daniela und Steifvater Michael auf
      die Veranstaltungen in dieser Kampagne. Mama Daniela freut sich wohl am
      meisten auf die Amtszeit ihrer Tochter und hat deshalb auch zwei der
      Kleider gesponsert.
    </p>
    <p>
      Weitere Informationen unter www.grosse3.de und
      www.facebook.com/diegrossen3.
    </p>

    <h2>Unsere Kampagnetermine 2022 - 2023</h2>
    <div class="table-responsive">
      <table class="table">
        <tbody>
          <tr>
            <td>12.11.2022</td>
            <td>Inthronisation</td>
            <td>Gemeindesaal St. Hildegard</td>
          </tr>
          <tr>
            <td>10. - 11.12.2022</td>
            <td>Weihnachtsmarkt</td>
            <td>Viernheimer Innenstadt</td>
          </tr>
          <tr>
            <td>15.01.2023</td>
            <td>Stadtempfang</td>
            <td>Ratssaal</td>
          </tr>
          <tr>
            <td>21.01.2023</td>
            <td>Spektakel (intern)</td>
            <td>MGV Vereinsheim</td>
          </tr>
          <tr>
            <td>28.01.2023, 19:33 Uhr</td>
            <td>Prunksitzung</td>
            <td>Bürgerhaus Viernheim</td>
          </tr>
          <tr>
            <td>18.02.2023</td>
            <td>Fastnachtssamstagsparty</td>
            <td>Sängereinheit Halle</td>
          </tr>
          <tr>
            <td>19.02.2023</td>
            <td>Straßenfastnacht</td>
            <td>Innenstadt Viernheim</td>
          </tr>
          <tr>
            <td>21.02.2023</td>
            <td>Umzug Lorsch</td>
            <td>Lorsch</td>
          </tr>
          <tr>
            <td>22.02.2023</td>
            <td>Heringsessen</td>
            <td>Deutsches Haus</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useActiveMeta, useMeta } from "vue-meta";

export default defineComponent({
  setup() {
    useMeta({
      title: "Aktuelle Beiträge rund um die Kampagne",
      og: {
        title: "Aktuelle Beiträge rund um die Kampagne",
      },
    });
    const metadata = useActiveMeta();
    return {
      metadata,
    };
  },
});
</script>
