import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import ImprintView from "@/views/ImprintView.vue";
import DataPrivacyView from "@/views/DataPrivacyView.vue";
import OrdenView from "@/views/OrdenView.vue";
import PrincessView from "@/views/PrincessView.vue";
import SocietyView from "@/views/SocietyView.vue";
import NewsView from "@/views/NewsView.vue";
import DatesView from "@/views/DatesView.vue";
import RedMariesView from "@/views/RedMariesView.vue";
import DownloadsView from "@/views/DownloadsView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/startseite",
    redirect: "/aktuelles",
  },
  {
    path: "/prunksitzung",
    redirect: "/aktuelles",
  },
  {
    path: "/kontakt",
    redirect: "/impressum",
  },
  {
    path: "/",
    component: NewsView,
  },
  {
    path: "",
    component: NewsView,
  },
  {
    path: "/aktuelles",
    component: NewsView,
  },
  {
    path: "/verein",
    component: SocietyView,
  },
  {
    path: "/termine",
    component: DatesView,
  },
  {
    path: "/red-maries",
    component: RedMariesView,
  },
  {
    path: "/downloads",
    component: DownloadsView,
  },
  {
    path: "/lieblichkeiten",
    component: PrincessView,
  },
  {
    path: "/orden",
    component: OrdenView,
  },
  {
    path: "/impressum",
    component: ImprintView,
  },
  {
    path: "/datenschutz",
    component: DataPrivacyView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
