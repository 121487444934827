
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "NavigationBar",

  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

    return { t };
  },

  components: {},

  data: () => ({
    showMenu: false,
  }),

  watch: {
    $route() {
      this.showMenu = false;
      window.scrollTo(0, 0);
    },
  },

  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
});
