<template>
  <div class="container bg-light px-5">
    <h1>Impressum</h1>

    <h2>Die Großen Drei Viernheim, Trägerverein e.V.</h2>

    <h3>1. Vorsitzender</h3>
    <p class="text-center mb-0">Philipp Haas</p>
    <p class="text-center mb-0">Annastr. 10</p>
    <p class="text-center">68519 Viernheim</p>

    <h3>2. Vorsitzender</h3>
    <p class="text-center mb-0">Moritz Gerner</p>
    <p class="text-center mb-0">Wiesenstr. 94 a</p>
    <p class="text-center">68519 Viernheim</p>

    <h3>Kassierer</h3>
    <p class="text-center mb-0">Frederick Lipp</p>
    <p class="text-center mb-0">Mannheimer Straße 25</p>
    <p class="text-center">68519 Viernheim</p>

    <h3>Präsident</h3>
    <p class="text-center mb-0">Tim Peterl</p>
    <p class="text-center mb-0">Joseph Haydn Straße 9</p>
    <p class="text-center">68519 Viernheim</p>

    <h3>Webmaster</h3>
    <p class="text-center mb-0">Manuel Härtle</p>
    <p class="text-center mb-0">August-Bebel-Straße 41</p>
    <p class="text-center">68519 Viernheim</p>

    <h2 class="text-left">Kontakt</h2>
    <p class="text-center">
      Senden Sie uns gerne eine E-Mail an
      <a href="mailto:kontakt@grosse3.de">kontakt@grosse3.de</a>
    </p>

    <h2 class="text-left">Inhalt</h2>
    <p>
      Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit,
      Vollständigkeit oder Qualität der bereitgestellten Informationen.
      Haftungsansprüche gegen den Autor, die sich auf Schäden materieller oder
      ideeller Art beziehen, welche durch die Nutzung oder Nichtnutzung der
      dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und
      unvollständiger Informationen verursacht wurden sind grundsätzlich
      ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches
      oder grob fahrlässiges Verschulden vorliegt. Alle Angebote sind
      freibleibend und unverbindlich. Der Autor behält es sich ausdrücklich vor,
      Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu
      verändern, zu ergänzen, zu loschen oder die Veroffentlichung zeitweise
      oder endgültig einzustellen.
    </p>

    <h2 class="text-left">Verweise und Links</h2>

    <p>
      Sofern auf Verweisziele (&quot;Links&quot;) direkt oder indirekt verwiesen
      wird, die außerhalb des Verantwortungsbereiches des Autors liegen, haftet
      dieser nur dann, wenn er von den Inhalten Kenntnis hat und es ihm
      technisch moglich und zumutbar wäre, die Nutzung im Falle rechtswidriger
      Inhalte zu verhindern. Für darüber hinausgehende Inhalte und insbesondere
      für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener
      Informationen entstehen, haftet allein der Anbieter dieser Seiten, nicht
      derjenige, der über Links auf die jeweilige Veroffentlichung lediglich
      verweist. Diese Einschränkung gilt gleichermaßen auch für Fremdeinträge in
      vom Autor eingerichteten Gästebüchern, Diskussionsforen und Linklisten.
    </p>

    <h2 class="text-left">Urheber- und Kennzeichenrecht</h2>

    <p>
      Alle Texte, Bilder und sonstigen Materialien dieser Publikation
      unterliegen den geltenden, aktuellen Urheberrechtsbestimmungen und dürfen
      nicht ohne schriftliche Genehmigung des oder der Verfasser bzw. des
      Fotografen verwendet werden
    </p>

    <h2 class="text-left">Rechtswirksamkeit dieses Haftungsausschlusses</h2>

    <p>
      Dieser Haftungsausschluss ist als Teil dieses Internetangebotes zu
      betrachten. Sofern Teile oder einzelne Formulierungen dieses Textes der
      geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen
      sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und
      ihrer Gültigkeit davon unberührt.
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useActiveMeta, useMeta } from "vue-meta";

export default defineComponent({
  setup() {
    useMeta({
      title: "Impressum",
      og: {
        title: "Impressum",
      },
    });
    const metadata = useActiveMeta();
    return {
      metadata,
    };
  },
});
</script>
