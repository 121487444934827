
import { useMeta, useActiveMeta } from "vue-meta";
import { defineComponent } from "vue";
import TopToolbar from "@/components/TopToolbar.vue";
import NavigationBar from "@/components/NavigationBar.vue";
import SiteFooter from "@/components/SiteFooter.vue";

export default defineComponent({
  name: "App",
  components: {
    SiteFooter,
    TopToolbar,
    NavigationBar,
  },
  setup() {
    useMeta({
      title: "Die Großen 3 Vierheim | Echte hausgemachte Vernemer Fastnacht",
      description:
        "Vereinspräsentation von Die Großen 3 Trägerverein Viernheim e.V.",
      og: {
        title: "Die Großen 3 Vierheim | Echte hausgemachte Vernemer Fastnacht",
        type: "Vereinspräsentation",
        locale: "de",
        url: "https://www.grosse3.de",
        site_name: "Die Großen 3 Viernheim",
        description:
          "Vereinspräsentation von Die Großen 3 Trägerverein Viernheim e.V.",
      },
      htmlAttrs: {
        lang: ["de"],
      },
      bodyAttrs: {},
    });
    const metadata = useActiveMeta();
    return {
      metadata,
    };
  },
});
