<template>
  <div class="container bg-light px-5">
    <h1>Datenschutz-Fachinformation nach DSGVO</h1>
    <ol>
      <li>
        <p class="font-weight-bold">Name und Anschrift des Verantwortlichen</p>

        <p class="mb-0">
          Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und
          anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie
          sonstiger datenschutzrechtlicher Bestimmungen ist der:
        </p>

        <p class="mb-0">Die Großen 3 Viernheim Trägerverein e.V:</p>

        <p class="mb-0">Annastr. 10</p>

        <p class="mb-0">68519 Viernheim</p>

        <p class="mb-0">Deutschland</p>

        <p class="mb-0">E-Mail: vorstand@grosse3.de</p>

        <p>Webseite: www.grosse3.de</p>
      </li>
      <li>
        <p class="font-weight-bold">
          Name und Anschrift des Datenschutzbeauftragten
        </p>

        <p>
          Ein Datenschutzbeauftragter ist nicht bestellt, da der Verein nicht
          der Notwendigkeit einer Bestellung eines Datenschutzbeauftragten
          unterliegt.
        </p>
      </li>
      <li>
        <p class="font-weight-bold">Allgemeines zur Datenverarbeitung</p>

        <ol>
          <li>
            <p class="font-weight-bold">
              Umfang der Verarbeitung personenbezogener Daten
            </p>

            <p>
              Wir verarbeiten personenbezogene Daten unserer Nutzer
              grundsätzlich nur, soweit dies zur Bereitstellung einer
              funktionsfähigen Website sowie unserer Inhalte und Leistungen
              erforderlich ist. Die Verarbeitung personenbezogener Daten unserer
              Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine
              Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung
              einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und
              die Verarbeitung der Daten durch gesetzliche Vorschriften
              gestattet ist.
            </p>
          </li>
          <li>
            <p class="font-weight-bold">
              Rechtsgrundlage für die Verarbeitung personenbezogener Daten
            </p>

            <p>
              Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine
              Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1
              lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.
            </p>

            <p>
              Bei der Verarbeitung von personenbezogenen Daten, die zur
              Erfüllung eines Vertrages, dessen Vertragspartei die betroffene
              Person ist, erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als
              Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur
              Durchführung vorvertraglicher Maßnahmen erforderlich sind.
            </p>

            <p>
              Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung
              einer rechtlichen Verpflichtung erforderlich ist, der unser
              Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als
              Rechtsgrundlage.
            </p>

            <p>
              Für den Fall, dass lebenswichtige Interessen der betroffenen
              Person oder einer anderen natürlichen Person eine Verarbeitung
              personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1
              lit. d DSGVO als Rechtsgrundlage.
            </p>

            <p>
              Ist die Verarbeitung zur Wahrung eines berechtigten Interesses
              unseres Unternehmens oder eines Dritten erforderlich und
              überwiegen die Interessen, Grundrechte und Grundfreiheiten des
              Betroffenen das erstgenannte Interesse nicht, so dient Art. 6 Abs.
              1 lit. f DSGVO als Rechtsgrundlage für die Verarbeitung.
            </p>
          </li>
          <li>
            <p class="font-weight-bold">Datenlöschung und Speicherdauer</p>

            <p>
              Die personenbezogenen Daten der betroffenen Person werden gelöscht
              oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine
              Speicherung kann darüber hinaus erfolgen, wenn dies durch den
              europäischen oder nationalen Gesetzgeber in unionsrechtlichen
              Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der
              Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder
              Löschung der Daten erfolgt auch dann, wenn eine durch die
              genannten Normen vorgeschriebene Speicherfrist abläuft, es sei
              denn, dass eine Erforderlichkeit zur weiteren Speicherung der
              Daten für einen Vertragsabschluss oder eine Vertragserfüllung
              besteht.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p class="font-weight-bold">
          Bereitstellung der Website und Erstellung von Logfiles
        </p>

        <ol>
          <li>
            <p class="font-weight-bold">
              Beschreibung und Umfang der Datenverarbeitung
            </p>

            <p>
              Bei jedem Aufruf unserer Internetseite erfasst unser System
              automatisiert Daten und Informationen vom Computersystem des
              aufrufenden Rechners.
            </p>

            <p>Folgende Daten werden hierbei erhoben:</p>

            <ul>
              <li>
                Informationen über den Browsertyp und die verwendete Version
              </li>
              <li>Das Betriebssystem des Nutzers</li>
              <li>Den Internet-Service-Provider des Nutzers</li>
              <li>Die gekürzte IP-Adresse des Nutzers</li>
              <li>Datum und Uhrzeit des Zugriffs</li>
              <li>
                Websites, von denen das System des Nutzers auf unsere
                Internetseite gelangt
              </li>
              <li>
                Websites, die vom System des Nutzers über unsere Website
                aufgerufen werden
              </li>
            </ul>

            <p>
              Die Daten werden ebenfalls in den Logfiles unseres Systems
              gespeichert. Eine Speicherung dieser Daten zusammen mit anderen
              personenbezogenen Daten des Nutzers findet nicht statt.
            </p>
          </li>
          <li>
            <p class="font-weight-bold">
              Rechtsgrundlage für die Datenverarbeitung
            </p>

            <p>
              Rechtsgrundlage für die vorübergehende Speicherung der Daten und
              der Logfiles ist Art. 6 Abs. 1 lit. f DSGVO.
            </p>
          </li>
          <li>
            <p class="font-weight-bold">Zweck der Datenverarbeitung</p>

            <p>
              Die vorübergehende Speicherung der IP-Adresse durch das System ist
              notwendig, um eine Auslieferung der Website an den Rechner des
              Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers
              für die Dauer der Sitzung gespeichert bleiben.
            </p>

            <p>
              Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der
              Website sicherzustellen. Zudem dienen uns die Daten zur
              Optimierung der Website und zur Sicherstellung der Sicherheit
              unserer informationstechnischen Systeme. Eine Auswertung der Daten
              zu Marketingzwecken findet in diesem Zusammenhang nicht statt.
            </p>

            <p>
              In diesen Zwecken liegt auch unser berechtigtes Interesse an der
              Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO.
            </p>
          </li>
          <li>
            <p class="font-weight-bold">Dauer der Speicherung</p>

            <p>
              Die Daten werden gelöscht, sobald sie für die Erreichung des
              Zweckes ihrer Erhebung nicht mehr erforderlich sind. Im Falle der
              Erfassung der Daten zur Bereitstellung der Website ist dies der
              Fall, wenn die jeweilige Sitzung beendet ist.
            </p>

            <p>
              Im Falle der Speicherung der Daten in Logfiles ist dies nach
              spätestens sieben Tagen der Fall. Eine darüberhinausgehende
              Speicherung ist möglich. In diesem Fall werden die IP-Adressen der
              Nutzer gelöscht oder verfremdet, sodass eine Zuordnung des
              aufrufenden Clients nicht mehr möglich ist.
            </p>
          </li>
          <li>
            <p class="font-weight-bold">
              Widerspruchs- und Beseitigungsmöglichkeit
            </p>

            <p>
              Die Erfassung der Daten zur Bereitstellung der Website und die
              Speicherung der Daten in Logfiles ist für den Betrieb der
              Internetseite zwingend erforderlich. Es besteht folglich seitens
              des Nutzers keine Widerspruchsmöglichkeit.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p class="font-weight-bold">Verwendung von Cookies</p>

        <ol>
          <li>
            <p class="font-weight-bold">
              Beschreibung und Umfang der Datenverarbeitung
            </p>

            <p>
              Unsere Webseite verwendet Cookies. Bei Cookies handelt es sich um
              Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf
              dem Computersystem des Nutzers gespeichert werden. Ruft ein Nutzer
              eine Website auf, so kann ein Cookie auf dem Betriebssystem des
              Nutzers gespeichert werden. Dieser Cookie enthält eine
              charakteristische Zeichenfolge, die eine eindeutige
              Identifizierung des Browsers beim erneuten Aufrufen der Website
              ermöglicht.
            </p>

            <p>
              Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu
              gestalten. Einige Elemente unserer Internetseite erfordern es,
              dass der aufrufende Browser auch nach einem Seitenwechsel
              identifiziert werden kann.
            </p>

            <p>
              In den Cookies werden dabei folgende Daten gespeichert und
              übermittelt:
            </p>

            <ul>
              <li>Spracheinstellungen</li>
              <li>Artikel in einem Warenkorb</li>
              <li>Log-In-Informationen</li>
            </ul>
          </li>
          <li>
            <p class="font-weight-bold">
              Rechtsgrundlage für die Datenverarbeitung
            </p>

            <p>
              Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten
              unter Verwendung von Cookies ist Art. 6 Abs. 1 lit. f DSGVO.
            </p>
          </li>
          <li>
            <p class="font-weight-bold">Zweck der Datenverarbeitung</p>

            <p>
              Der Zweck der Verwendung technisch notwendiger Cookies ist, die
              Nutzung von Websites für die Nutzer zu vereinfachen. Einige
              Funktionen unserer Internetseite können ohne den Einsatz von
              Cookies nicht angeboten werden. Für diese ist es erforderlich,
              dass der Browser auch nach einem Seitenwechsel wiedererkannt wird.
            </p>

            <p>Für folgende Anwendungen benötigen wir Cookies:</p>

            <p>
              hidealertbanner: Dieses Cookie bewirkt, dass Ihnen das
              Hinweisbanner zur Datenerhebung nicht erneut angezeigt wird. Das
              Cookie läuft nach 365 Tagen ab, Sie bekommen also spätestens dann
              erneut den Hinweis zur Datenerhebung angezeigt. Um Ihnen die
              Sicherheit zu geben, von jeder Änderung des Hinweistextes zur
              Datenerhebung auch innerhalb der Gültigkeitsfrist zu erfahren,
              wird nach jeder textlichen Änderung die fortlaufende Nummer um 1
              erhöht. Dies kann dazu führen, dass das Cookie mehrfach auf Ihrem
              Computer gespeichert wird (hidealertbanner1, hidealertbanner2
              usw.).
            </p>

            <p>
              PHPSESSID: Dieses Cookie ist ein sogenanntes Sitzungscookie und
              wird beim Beenden der Browsersitzung gelöscht. Benötigt wird
              dieses Cookie für die Realisierung einzelner PHP-Anwendungen auf
              unseren Seiten.
            </p>

            <p>
              PYPF: Dieses Cookies wird durch die Webseite paypalobjects.com
              beim Besuch der Seite Spenden &amp; Unterstützen gesetzt und
              ermöglicht uns die Darstellung des Paypal-Spendenbuttons. Das
              Cookie hat eine Gültigkeit von einem Monat.
            </p>

            <p>
              NID: Dieses Cookie wird auf verschiedenen Seiten von google.com
              gesetzt und dient der interaktiven Darstellung von Karten. Das
              Cookie hat eine Gültigkeit von 6 Monaten.
            </p>

            <p>
              Die durch technisch notwendige Cookies erhobenen Nutzerdaten
              werden nicht zur Erstellung von Nutzerprofilen verwendet.
            </p>
          </li>
          <li>
            <p class="font-weight-bold">
              Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit
            </p>

            <p>
              Cookies werden auf dem Rechner des Nutzers gespeichert und von
              diesem an unserer Seite übermittelt. Daher haben Sie als Nutzer
              auch die volle Kontrolle über die Verwendung von Cookies. Durch
              eine Änderung der Einstellungen in Ihrem Internetbrowser können
              Sie die Übertragung von Cookies deaktivieren oder einschränken.
              Bereits gespeicherte Cookies können jederzeit gelöscht werden.
              Dies kann auch automatisiert erfolgen. Werden Cookies für unsere
              Website deaktiviert, können möglicherweise nicht mehr alle
              Funktionen der Website vollumfänglich genutzt werden.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p class="font-weight-bold">Newsletter</p>

        <p>
          Wir behalten uns die Erstellung eines Newsletters per E-Mail und RSS
          Feed vor.
        </p>
      </li>
      <li>
        <p class="font-weight-bold">Registrierung</p>

        <ol>
          <li>
            <p class="font-weight-bold">
              Beschreibung und Umfang der Datenverarbeitung
            </p>

            <p>
              Auf unserer Internetseite bieten wir Nutzern die Möglichkeit, sich
              unter Angabe personenbezogener Daten zu registrieren. Dies
              betrifft:
            </p>

            <ul>
              <li>Allgemeine Kontaktanfragen</li>
            </ul>

            <p>
              Die Daten werden dabei in eine Eingabemaske eingegeben und an uns
              übermittelt und gespeichert. Eine Weitergabe der Daten an Dritte
              findet nicht statt. Folgende Daten werden im Rahmen des
              Registrierungsprozesses erhoben:
            </p>

            <p>
              Im Zeitpunkt der Registrierung werden zudem folgende Daten
              gespeichert:
            </p>

            <ul>
              <li>Die gekürzte IP-Adresse des Nutzers</li>
              <li>Datum und Uhrzeit der Registrierung</li>
              <li>Individuelle Angaben je nach Formular</li>
            </ul>
          </li>
          <li>
            <p class="font-weight-bold">
              Rechtsgrundlage für die Datenverarbeitung
            </p>

            <p>
              Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen
              einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
            </p>

            <p>
              Dient die Registrierung der Erfüllung eines Vertrages, dessen
              Vertragspartei der Nutzer ist oder der Durchführung
              vorvertraglicher Maßnahmen, so ist zusätzliche Rechtsgrundlage für
              die Verarbeitung der Daten Art. 6 Abs. 1 lit. b DSGVO.
            </p>
          </li>
          <li>
            <p class="font-weight-bold">Zweck der Datenverarbeitung</p>

            <p>
              Eine Registrierung des Nutzers ist zur Erfüllung eines Vertrages
              mit dem Nutzer oder zur Durchführung vorvertraglicher Maßnahmen
              erforderlich.
            </p>
          </li>
          <li>
            <p class="font-weight-bold">Dauer der Speicherung</p>

            <p>
              Die Daten werden gelöscht, sobald sie für die Erreichung des
              Zweckes ihrer Erhebung nicht mehr erforderlich sind.
            </p>

            <p>
              Dies ist für die während des Registrierungsvorgangs zur Erfüllung
              eines Vertrags oder zur Durchführung vorvertraglicher Maßnahmen
              dann der Fall, wenn die Daten für die Durchführung des Vertrages
              nicht mehr erforderlich sind. Auch nach Abschluss des Vertrags
              kann eine Erforderlichkeit, personenbezogene Daten des
              Vertragspartners zu speichern, bestehen, um vertraglichen oder
              gesetzlichen Verpflichtungen nachzukommen.
            </p>

            <p>&nbsp;</p>
          </li>
          <li>
            <p class="font-weight-bold">
              Widerspruchs- und Beseitigungsmöglichkeit
            </p>

            <p>
              Als Nutzer haben sie jederzeit die Möglichkeit, die Registrierung
              aufzulösen. Die über Sie gespeicherten Daten können Sie jederzeit
              abändern lassen.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p class="font-weight-bold">Kontaktformular und E-Mail-Kontakt</p>

        <ol>
          <li>
            <p class="font-weight-bold">
              Beschreibung und Umfang der Datenverarbeitung
            </p>

            <p>
              Auf unserer Internetseite ist ein Kontaktformular vorhanden,
              welches für die elektronische Kontaktaufnahme genutzt werden kann.
              Nimmt ein Nutzer diese Möglichkeit wahr, so werden die in der
              Eingabemaske eingegeben Daten an uns übermittelt und gespeichert.
              Diese Daten sind:
            </p>

            <p>
              Im Zeitpunkt der Absendung der Nachricht werden zudem folgende
              Daten gespeichert:
            </p>

            <ul>
              <li>Die gekürzte IP-Adresse des Nutzers</li>
              <li>Datum und Uhrzeit der Registrierung</li>
            </ul>

            <p>
              Für die Verarbeitung der Daten wird im Rahmen des Absendevorgangs
              Ihre Einwilligung eingeholt und auf diese Datenschutzerklärung
              verwiesen.
            </p>

            <p>
              Alternativ ist eine Kontaktaufnahme über die bereitgestellte
              E-Mail-Adresse möglich. In diesem Fall werden die mit der E-Mail
              übermittelten personenbezogenen Daten des Nutzers gespeichert.
            </p>

            <p>
              Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an
              Dritte. Die Daten werden ausschließlich für die Verarbeitung der
              Konversation verwendet.
            </p>
          </li>
          <li>
            <p class="font-weight-bold">
              Rechtsgrundlage für die Datenverarbeitung
            </p>

            <p>
              Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen
              einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
            </p>

            <p>
              Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer
              Übersendung einer E-Mail übermittelt werden, ist Art. 6 Abs. 1
              lit. f DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines
              Vertrages ab, so ist zusätzliche Rechtsgrundlage für die
              Verarbeitung Art. 6 Abs. 1 lit. b DSGVO.
            </p>
          </li>
          <li>
            <p class="font-weight-bold">Zweck der Datenverarbeitung</p>

            <p>
              Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske
              dient uns allein zur Bearbeitung der Kontaktaufnahme. Im Falle
              einer Kontaktaufnahme per E-Mail liegt hieran auch das
              erforderliche berechtigte Interesse an der Verarbeitung der Daten.
            </p>

            <p>
              Die sonstigen während des Absendevorgangs verarbeiteten
              personenbezogenen Daten dienen dazu, einen Missbrauch des
              Kontaktformulars zu verhindern und die Sicherheit unserer
              informationstechnischen Systeme sicherzustellen.
            </p>
          </li>
          <li>
            <p class="font-weight-bold">Dauer der Speicherung</p>

            <p>
              Die Daten werden gelöscht, sobald sie für die Erreichung des
              Zweckes ihrer Erhebung nicht mehr erforderlich sind. Für die
              personenbezogenen Daten aus der Eingabemaske des Kontaktformulars
              und diejenigen, die per E-Mail übersandt wurden, ist dies dann der
              Fall, wenn die jeweilige Konversation mit dem Nutzer beendet ist.
              Beendet ist die Konversation dann, wenn sich aus den Umständen
              entnehmen lässt, dass der betroffene Sachverhalt abschließend
              geklärt ist.
            </p>
          </li>
          <li>
            <p class="font-weight-bold">
              Widerspruchs- und Beseitigungsmöglichkeit
            </p>

            <p>
              Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur
              Verarbeitung der personenbezogenen Daten zu widerrufen. Nimmt der
              Nutzer per E-Mail Kontakt mit uns auf, so kann er der Speicherung
              seiner personenbezogenen Daten jederzeit widersprechen. In einem
              solchen Fall kann die Konversation nicht fortgeführt werden.
            </p>

            <p>
              Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme
              gespeichert wurden, werden in diesem Fall gelöscht.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p class="font-weight-bold">Webanalyse</p>

        <p>
          Soweit die Einwilligung erklärt wurde, wird auf dieser Website Google
          Analytics eingesetzt, ein Webanalysedienst der Google LLC
          (&quot;Google&quot;). Die Nutzung umfasst die Betriebsart
          &quot;Universal Analytics&quot;. Hierdurch ist es möglich, Daten,
          Sitzungen und Interaktionen über mehrere Geräte hinweg einer
          pseudonymen User-ID zuzuordnen und so die Aktivitäten eines Nutzers
          geräteübergreifend zu analysieren. Dieser Datenschutzhinweis wird zur
          Verfügung gestellt von https://www.intersoft-consulting.de.
        </p>

        <p>
          Google Analytics verwendet sog. &quot;Cookies&quot;, Textdateien, die
          auf dem Computer gespeichert werden und die eine Analyse der Benutzung
          der Website ermöglichen. Die durch das Cookie erzeugten Informationen
          über die Benutzung der Website werden in der Regel an einen Server von
          Google in den USA übertragen und dort gespeichert. Im Falle der
          Aktivierung der IP-Anonymisierung auf dieser Website, wird Ihre
          IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der
          Europäischen Union oder in anderen Vertragsstaaten des Abkommens über
          den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen
          wird die volle IP-Adresse an einen Server von Google in den USA
          übertragen und dort gekürzt. Wir weisen Sie darauf hin, dass auf
          dieser Webseite Google Analytics um eine IP-Anonymisierung erweitert
          wurde, um eine anonymisierte Erfassung von IP-Adressen (sog.
          IP-Masking) zu gewährleisten. Die im Rahmen von Google Analytics von
          Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von
          Google zusammengeführt. Nähere Informationen zu Nutzungsbedingungen
          und Datenschutz finden Sie unter
          https://&shy;www.&shy;google.&shy;com/&shy;analytics/&shy;terms/&shy;de.&shy;html
          bzw. unter https://policies.google.com/?hl=de.
        </p>

        <p class="font-weight-bold">Zwecke der Verarbeitung</p>

        <p>
          Im Auftrag des Betreibers dieser Website wird Google diese
          Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um
          Reports über die Websiteaktivitäten zusammenzustellen und um weitere
          mit der Websitenutzung und der Internetnutzung verbundene
          Dienstleistungen gegenüber dem Websitebetreiber zu erbringen
        </p>

        <p class="font-weight-bold">Rechtsgrundlage</p>

        <p>
          Die Rechtsgrundlage für den Einsatz von Google Analytics ist die
          Einwilligung gemäß https://dsgvo-gesetz.de/art-6-dsgvo/.
        </p>

        <p class="font-weight-bold">Empfänger / Kategorien von Empfängern</p>

        <p>Der Empfänger der erhobenen Daten ist Google.</p>

        <p class="font-weight-bold">Übermittlung in Drittstaaten</p>

        <p>
          Die personenbezogenen Daten werden unter dem EU-US Privacy Shield auf
          Grundlage des Angemessenheitsbeschlusses der Europäischen Kommission
          in die USA übermittelt. Das Zertifikat können Sie
          https://www.&shy;privacyshield.&shy;gov/&shy;participant?&shy;id=a2zt000000001L5AAI
          abrufen.
        </p>

        <p class="font-weight-bold">Dauer der Datenspeicherung</p>

        <p>
          Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B.
          User-ID) oder Werbe-IDs verknüpften Daten werden nach 14 Monaten
          automatisch gelöscht. Die Löschung von Daten, deren Aufbewahrungsdauer
          erreicht ist, erfolgt automatisch einmal im Monat.
        </p>

        <p class="font-weight-bold">Betroffenenrechte</p>

        <p>
          Die Einwilligung kann jederzeit mit Wirkung für die Zukunft widerrufen
          werden, indem Sie die Speicherung der Cookies durch eine entsprechende
          Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
          darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
          Funktionen dieser Website vollumfänglich werden nutzen können.
        </p>

        <p>
          Darüber hinaus können die Erfassung der durch das Cookie erzeugten und
          auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse)
          an Google sowie die Verarbeitung dieser Daten durch Google verhindert
          werden, indem Sie das
          https://&shy;tools.&shy;google.&shy;com/&shy;dlpage/&shy;gaoptout?hl=de
          Browser-Add-on herunterladen und installieren.
        </p>

        <p>
          Opt-Out-Cookies verhindern die zukünftige Erfassung der Daten beim
          Besuch dieser Website. Um die Erfassung durch Universal Analytics
          überverschiedene Geräte hinweg zu verhindern, müssen das Opt-Out auf
          allen genutzten Systemen durchgeführt werden.
        </p>
      </li>
      <li>
        <p class="font-weight-bold">Rechte der betroffenen Person</p>

        <p>
          Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie
          Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte gegenüber
          dem Verantwortlichen zu:
        </p>

        <ol>
          <li>
            <p class="font-weight-bold">Auskunftsrecht</p>

            <p>
              Sie können von dem Verantwortlichen eine Bestätigung darüber
              verlangen, ob personenbezogene Daten, die Sie betreffen, von uns
              verarbeitet werden.
            </p>

            <p>
              Liegt eine solche Verarbeitung vor, können Sie von dem
              Verantwortlichen über folgende Informationen Auskunft verlangen:
            </p>

            <ul>
              <li>
                die Zwecke, zu denen die personenbezogenen Daten verarbeitet
                werden;
              </li>
              <li>
                die Kategorien von personenbezogenen Daten, welche verarbeitet
                werden;
              </li>
              <li>
                die Empfänger bzw. die Kategorien von Empfängern, gegenüber
                denen die Sie betreffenden personenbezogenen Daten offengelegt
                wurden oder noch offengelegt werden;
              </li>
              <li>
                die geplante Dauer der Speicherung der Sie betreffenden
                personenbezogenen Daten oder, falls konkrete Angaben hierzu
                nicht möglich sind, Kriterien für die Festlegung der
                Speicherdauer;
              </li>
              <li>
                das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie
                betreffenden personenbezogenen Daten, eines Rechts auf
                Einschränkung der Verarbeitung durch den Verantwortlichen oder
                eines Widerspruchsrechts gegen diese Verarbeitung;
              </li>
              <li>
                das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
              </li>
              <li>
                alle verfügbaren Informationen über die Herkunft der Daten, wenn
                die personenbezogenen Daten nicht bei der betroffenen Person
                erhoben werden;
              </li>
              <li>
                das Bestehen einer automatisierten Entscheidungsfindung
                einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und
                &ndash; zumindest in diesen Fällen &ndash; aussagekräftige
                Informationen über die involvierte Logik sowie die Tragweite und
                die angestrebten Auswirkungen einer derartigen Verarbeitung für
                die betroffene Person.
              </li>
            </ul>

            <p>
              Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die
              Sie betreffenden personenbezogenen Daten in ein Drittland oder an
              eine internationale Organisation übermittelt werden. In diesem
              Zusammenhang können Sie verlangen, über die geeigneten Garantien
              gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung
              unterrichtet zu werden.
            </p>
          </li>
          <li>
            <p class="font-weight-bold">Recht auf Berichtigung</p>

            <p>
              Sie haben ein Recht auf Berichtigung und/oder Vervollständigung
              gegenüber dem Verantwortlichen, sofern die verarbeiteten
              personenbezogenen Daten, die Sie betreffen, unrichtig oder
              unvollständig sind. Der Verantwortliche hat die Berichtigung
              unverzüglich vorzunehmen.
            </p>
          </li>
          <li>
            <p class="font-weight-bold">
              Recht auf Einschränkung der Verarbeitung
            </p>

            <p>
              Unter den folgenden Voraussetzungen können Sie die Einschränkung
              der Verarbeitung der Sie betreffenden personenbezogenen Daten
              verlangen:
            </p>

            <ul>
              <li>
                wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen
                für eine Dauer bestreiten, die es dem Verantwortlichen
                ermöglicht, die Richtigkeit der personenbezogenen Daten zu
                überprüfen;
              </li>
              <li>
                die Verarbeitung unrechtmäßig ist und Sie die Löschung der
                personenbezogenen Daten ablehnen und stattdessen die
                Einschränkung der Nutzung der personenbezogenen Daten verlangen;
              </li>
              <li>
                der Verantwortliche die personenbezogenen Daten für die Zwecke
                der Verarbeitung nicht länger benötigt, Sie diese jedoch zur
                Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                benötigen, oder
              </li>
              <li>
                wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1
                DSGVO eingelegt haben und noch nicht feststeht, ob die
                berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen
                überwiegen.
              </li>
            </ul>

            <p>
              Wurde die Verarbeitung der Sie betreffenden personenbezogenen
              Daten eingeschränkt, dürfen diese Daten &ndash; von ihrer
              Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur
              Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
              oder zum Schutz der Rechte einer anderen natürlichen oder
              juristischen Person oder aus Gründen eines wichtigen öffentlichen
              Interesses der Union oder eines Mitgliedstaats verarbeitet werden.
            </p>

            <p>
              Wurde die Einschränkung der Verarbeitung nach den o.g.
              Voraussetzungen eingeschränkt, werden Sie von dem Verantwortlichen
              unterrichtet bevor die Einschränkung aufgehoben wird.
            </p>
          </li>
          <li>
            <p class="font-weight-bold">Recht auf Löschung</p>

            <p class="font-weight-bold">Löschungspflicht</p>

            <p>
              Sie können von dem Verantwortlichen verlangen, dass die Sie
              betreffenden personenbezogenen Daten unverzüglich gelöscht werden,
              und der Verantwortliche ist verpflichtet, diese Daten unverzüglich
              zu löschen, sofern einer der folgenden Gründe zutrifft:
            </p>

            <ul>
              <li>
                Die Sie betreffenden personenbezogenen Daten sind für die
                Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet
                wurden, nicht mehr notwendig.
              </li>
              <li>
                Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung
                gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO
                stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für
                die Verarbeitung.
              </li>
              <li>
                Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die
                Verarbeitung ein und es liegen keine vorrangigen berechtigten
                Gründe für die Verarbeitung vor, oder Sie legen gem. Art. 21
                Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.
              </li>
              <li>
                Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig
                verarbeitet.
              </li>
              <li>
                Die Löschung der Sie betreffenden personenbezogenen Daten ist
                zur Erfüllung einer rechtlichen Verpflichtung nach dem
                Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem
                der Verantwortliche unterliegt.
              </li>
              <li>
                Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf
                angebotene Dienste der Informationsgesellschaft gemäß Art. 8
                Abs. 1 DSGVO erhoben.
              </li>
            </ul>

            <p class="font-weight-bold">Information an Dritte</p>

            <p>
              Hat der Verantwortliche die Sie betreffenden personenbezogenen
              Daten öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu
              deren Löschung verpflichtet, so trifft er unter Berücksichtigung
              der verfügbaren Technologie und der Implementierungskosten
              angemessene Maßnahmen, auch technischer Art, um für die
              Datenverarbeitung Verantwortliche, die die personenbezogenen Daten
              verarbeiten, darüber zu informieren, dass Sie als betroffene
              Person von ihnen die Löschung aller Links zu diesen
              personenbezogenen Daten oder von Kopien oder Replikationen dieser
              personenbezogenen Daten verlangt haben.
            </p>

            <p class="font-weight-bold">Ausnahmen</p>

            <p>
              Das Recht auf Löschung besteht nicht, soweit die Verarbeitung
              erforderlich ist
            </p>

            <ul>
              <li>
                zur Ausübung des Rechts auf freie Meinungsäußerung und
                Information;
              </li>
              <li>
                zur Erfüllung einer rechtlichen Verpflichtung, die die
                Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten,
                dem der Verantwortliche unterliegt, erfordert, oder zur
                Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt
                oder in Ausübung öffentlicher Gewalt erfolgt, die dem
                Verantwortlichen übertragen wurde;
              </li>
              <li>
                aus Gründen des öffentlichen Interesses im Bereich der
                öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie
                Art. 9 Abs. 3 DSGVO;
              </li>
              <li>
                für im öffentlichen Interesse liegende Archivzwecke,
                wissenschaftliche oder historische Forschungszwecke oder für
                statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter
                Abschnitt a) genannte Recht voraussichtlich die Verwirklichung
                der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft
                beeinträchtigt, oder
              </li>
              <li>
                zur Geltendmachung, Ausübung oder Verteidigung von
                Rechtsansprüchen.
              </li>
            </ul>
          </li>
          <li>
            <p class="font-weight-bold">Recht auf Unterrichtung</p>

            <p>
              Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung
              der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht,
              ist dieser verpflichtet, allen Empfängern, denen die Sie
              betreffenden personenbezogenen Daten offengelegt wurden, diese
              Berichtigung oder Löschung der Daten oder Einschränkung der
              Verarbeitung mitzuteilen, es sei denn, dies erweist sich als
              unmöglich oder ist mit einem unverhältnismäßigen Aufwand
              verbunden.
            </p>

            <p>
              Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über
              diese Empfänger unterrichtet zu werden.
            </p>
          </li>
          <li>
            <p class="font-weight-bold">Recht auf Datenübertragbarkeit</p>

            <p>
              Sie haben das Recht, die Sie betreffenden personenbezogenen Daten,
              die Sie dem Verantwortlichen bereitgestellt haben, in einem
              strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
              Außerdem haben Sie das Recht diese Daten einem anderen
              Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem
              die personenbezogenen Daten bereitgestellt wurden, zu übermitteln,
              sofern
            </p>

            <p>
              In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken,
              dass die Sie betreffenden personenbezogenen Daten direkt von einem
              Verantwortlichen einem anderen Verantwortlichen übermittelt
              werden, soweit dies technisch machbar ist. Freiheiten und Rechte
              anderer Personen dürfen hierdurch nicht beeinträchtigt werden.
            </p>

            <p>
              Das Recht auf Datenübertragbarkeit gilt nicht für eine
              Verarbeitung personenbezogener Daten, die für die Wahrnehmung
              einer Aufgabe erforderlich ist, die im öffentlichen Interesse
              liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem
              Verantwortlichen übertragen wurde.
            </p>

            <ul>
              <li>
                die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit.
                a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag
                gem. Art. 6 Abs. 1 lit. b DSGVO beruht und
              </li>
              <li>
                die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
              </li>
            </ul>
          </li>
          <li>
            <p class="font-weight-bold">Widerspruchsrecht</p>

            <p>
              Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen
              Situation ergeben, jederzeit gegen die Verarbeitung der Sie
              betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs.
              1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt
              auch für ein auf diese Bestimmungen gestütztes Profiling.
            </p>

            <p>
              Der Verantwortliche verarbeitet die Sie betreffenden
              personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende
              schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
              Interessen, Rechte und Freiheiten überwiegen, oder die
              Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung
              von Rechtsansprüchen.
            </p>

            <p>
              Werden die Sie betreffenden personenbezogenen Daten verarbeitet,
              um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit
              Widerspruch gegen die Verarbeitung der Sie betreffenden
              personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen;
              dies gilt auch für das Profiling, soweit es mit solcher
              Direktwerbung in Verbindung steht.
            </p>

            <p>
              Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung,
              so werden die Sie betreffenden personenbezogenen Daten nicht mehr
              für diese Zwecke verarbeitet.
            </p>

            <p>
              Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von
              Diensten der Informationsgesellschaft &ndash; ungeachtet der
              Richtlinie 2002/58/EG &ndash; Ihr Widerspruchsrecht mittels
              automatisierter Verfahren auszuüben, bei denen technische
              Spezifikationen verwendet werden.
            </p>
          </li>
          <li>
            <p class="font-weight-bold">
              Recht auf Widerruf der datenschutzrechtlichen
              Einwilligungserklärung
            </p>

            <p>
              Sie haben das Recht, Ihre datenschutzrechtliche
              Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf
              der Einwilligung wird die Rechtmäßigkeit der aufgrund der
              Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht
              berührt.
            </p>
          </li>
          <li>
            <p class="font-weight-bold">
              Automatisierte Entscheidung im Einzelfall einschließlich Profiling
            </p>

            <p>Eine automatisierte Entscheidung findet nicht statt.</p>
          </li>
          <li>
            <p class="font-weight-bold">
              Recht auf Beschwerde bei einer Aufsichtsbehörde
            </p>

            <p>
              Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
              gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde
              bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat
              ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des
              mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die
              Verarbeitung der Sie betreffenden personenbezogenen Daten gegen
              die DSGVO verstößt.
            </p>

            <p class="mb-0">Zuständige Aufsichtsbehörde ist der</p>

            <p class="mb-0">
              Der Hessische Beauftragte für Datenschutz und Informationsfreiheit
            </p>

            <p class="mb-0">Prof. Dr. Michael Ronellenfitsch</p>

            <p class="mb-0">Gustav-Stresemann-Ring 1</p>

            <p class="mb-0">65189 Wiesbaden</p>

            <p class="mb-0">Tel.: 0611-1408-0</p>

            <p class="mb-0">Fax: 0611-1408-611</p>

            <p class="mb-0">Email: bdsb@datenschutz.hessen.de</p>

            <p>
              Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde,
              unterrichtet den Beschwerdeführer über den Stand und die
              Ergebnisse der Beschwerde einschließlich der Möglichkeit eines
              gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.
            </p>
          </li>
        </ol>
      </li>
    </ol>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useActiveMeta, useMeta } from "vue-meta";

export default defineComponent({
  setup() {
    useMeta({
      title: "Datenschutzbestimmungen",
      og: {
        title: "Datenschutzbestimmungen",
      },
    });
    const metadata = useActiveMeta();
    return {
      metadata,
    };
  },
});
</script>
