<template>
  <div class="container bg-light px-5">
    <h1>Mitglied werden - Die Großen 3 unterstützen</h1>

    <p class="text-center">
      Am Schmutzigen Donnerstag der Kampagne 2013/2014 wurde der &quot;Die
      Großen 3 Viernheim Trägerverein e.V.&quot; gegründet. Dieser Schritt wurde
      nötig, um die Arbeit der Fastnachter der Großen 3 weiterhin auch
      versicherungsrechtlich abgesichert möglich zu machen. Außerdem gibt es für
      alle Freunde der Großen 3 damit die Möglichkeit, offiziell als Mitglied
      bei den Großen 3 einzutreten und mit Mitgliedsbeiträgen die Arbeit zu
      unterstützen (siehe Link unten oder unter Downloads).
    </p>

    <p class="text-center">
      Natürlich wird sich an den traditionellen Strukturen nichts ändern. Die
      Kampagne, und alles was dazugehört, wird auch weiterhin vom Team der
      Großen 3 gestaltet. Das Team wird auch weiterhin den Präsidenten und das
      Präsidium wählen, welche die Großen 3 durch die Kampagne führen.
    </p>

    <p>&nbsp;</p>

    <p class="text-center">Wir würden uns über viele neue Mitglieder freuen!</p>

    <p class="text-center">
      <a href="/Beitrittserklarung.pdf" target="_blank">
        Wenn ihr hier klickt, gelangt ihr zu unserer Beitrittserklärung!
      </a>
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useActiveMeta, useMeta } from "vue-meta";

export default defineComponent({
  setup() {
    useMeta({
      title: "Über die Großen 3 - Der Verein",
      og: {
        title: "Über die Großen 3 - Der Verein",
      },
    });
    const metadata = useActiveMeta();
    return {
      metadata,
    };
  },
});
</script>
